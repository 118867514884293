import React from 'react';
import DocsLayout from '../../components/ui/DocsLayout';

const Components = () => (
  <DocsLayout>
    <h1>What are components</h1>
    <p>
      Components are the building blocks of a website. They can be small and
      simple and just show a heading or they can be complex and have business
      logic.
    </p>
    <p>
      Everything in Gatsby/React is a component. A page is a component and a
      page will contain components.
    </p>
    <p>
      The great things about props is&nbsp;that they can be recycled. If we were
      going to make another website&nbsp;at TAG we can reuse all of these
      components making web development&nbsp;a lot faster
    </p>
    <p>Eg a page will look something like this</p>
    <p>
      <em>&lt;Layout&gt; //Make the page match the standard layout</em>
    </p>
    <p>
      <em>
        &nbsp; &lt;Header title="Hello World" image="an/image/source" /&gt; //
        Props dictate the info displayed by the component
      </em>
    </p>
    <p>
      <em>&nbsp; &lt;Footer &gt; //shows the footer</em>
    </p>
    <p>
      <em>&lt;/Layout&gt;</em>
    </p>
    <p>
      The below library shows all components used on the the SpotCast website,
      and the types of props that are accepted.
    </p>
    <p>&nbsp;</p>
  </DocsLayout>
);

export default Components;
